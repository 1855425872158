<template>
    <div>
        <v-app-bar
            id="home-app-bar"
            color='secondary'
            elevation="1"
            height= "70"
            fixed
        >
            <v-list-item class="logo-fresar">
                <router-link to="/" >
                    <img :src="require('@/assets/logo.png')" alt="" class="m-logo">
                </router-link>
                <!-- <a href="/">
                    
                </a> -->
            </v-list-item>

            <v-spacer />

            <div class="fresar-navbar">
                <v-tabs
                    class="hidden-sm-and-down"
                    grow
                    background-color= "primary"
                >
                    <v-tab
                        v-for="(name, i) in items"
                        :key="i"
                        :to="{ name }"
                        :exact="name === 'Home'"
                        :ripple="false"
                        active-class="text-white"
                        class="font-weight-normal tx-color-tab text-xl-subtitle-1 text-lg-subtitle-2"
                        min-width="96"
                        text
                    >
                        {{ name }}
                    </v-tab>
                </v-tabs>
                <v-app-bar-nav-icon
                    class="hidden-md-and-up"
                    @click="drawer = !drawer"
                />
            </div>
        </v-app-bar>

        <home-drawer
            v-model="drawer"
            :items="items"
        />
    </div>
</template>

<script>
export default {
    name: 'HomeAppBar',

    components: {
        HomeDrawer: () => import('./Drawer'),
    },

    data:() => ({
        drawer: null,
        items: [
            'Nosotros',
            'Servicios',
            'Equipos',
            'Contacto'
        ]
    }),
}
</script>

<style lang="scss">
#home-app-bar {
    overflow: hidden;
    .logo-fresar {
        .m-logo {
            width: 35%;
            margin: auto;
            display: block;
        }
    }
    .tx-color-tab { color: white; }
    .fresar-navbar { 
        position: relative;
        .v-tabs-slider-wrapper { width: 0 !important; }
        .v-tab--active {
            font-weight: 900;
            &:hover::before {  opacity: 0; }
        }
        a.v-tab {
            &:before {
                background-color: white;
                height: 2px;
                top: auto;
                bottom: 3px;
                width: 0;
                transition: width .5s ease;
            }
            &:hover::before { 
                width: 100%;
                opacity: 1;
            }
        }
        &::before {
            content: '';
            position: absolute;
            top: -1vw;
            right: -15vw;
            width: 220%;
            height: 85px;   
            background-color: #F35E22;
            transform: skewX(140deg);
        }
        [role="tablist"] { padding-top: 0.5vw; }
    }

    @media (max-width: 1300px) and (min-width: 1069px) {
        .fresar-navbar::before { width: 200%; }
    }

    @media (max-width: 1070px) and (min-width: 1009px) {
        .fresar-navbar::before { width: 170%; }
    }

    @media (max-width: 1010px) and (min-width: 800px) {
        .fresar-navbar::before { width: 140%; }
    }

    @media (max-width: 800px) {
        .fresar-navbar::before { background-color: transparent }
    }
}
</style>